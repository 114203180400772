<template>
    <div class="home">
        <!-- table -->
        <!-- 此处这么处理是因为：当弹框的时候，多选框会显示 -->
        <template v-if="!presentVisible"> 
            <div class="tablewrapper">
                <el-table ref="multipleTable" :data="list.list" tooltip-effect="dark" stripe>
                    <el-table-column type="index" width="40" label="#"></el-table-column>
                    <el-table-column prop="typeZh" label="类型" min-width="60" align="center"></el-table-column>
                    <el-table-column prop="updatedAt" label="更新时间" :formatter="formatter" min-width="90" align="center" show-overflow-tooltip></el-table-column>

                    <el-table-column label="操作" min-width="120" align="center">
                        <template #default="scope">
                            <el-button size="mini" type="primary" @click="handleDes(scope.$index, scope.row)">简短描述</el-button>
                            <el-button size="mini" type="primary" @click="handleBrief(scope.$index, scope.row)">详细内容</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </template>
        <!-- 内容编辑 -->
        <template v-if="presentVisible">
            <div class="dialog-ckeditor-bg">
                <div class="ckeditor-header">
                    <el-button @click="presentVisible = false" style="width: 100px; margin: auto 0;">取消编辑</el-button>
                    <div class="ckeditor-title">{{ dialogTitle }}编辑</div>
                    <el-button type="primary" @click="handleBriefSure" style="width: 100px; margin: auto 0;">保存编辑</el-button>
                </div>
                <div class="dialog-ckeditor"> 
                    <ckeditor class="ckeditor" :editor="editor" @ready="ckeditorReady" v-model="editorData" :config="editorConfig"></ckeditor>
                </div>
            </div>
        </template>
        <!-- 新增或编辑个人信息 -->
        <el-dialog :title="dialogTitle" v-model="dialogVisible">  
            <el-form label-width="100px" :model="newQuery" ref="addQuery" :rules="rules">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="类型标题：" prop="typeZh">
                            <el-input v-model="newQuery.typeZh" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="类型简介：" prop="brief">
                            <el-input type="textarea" v-model="newQuery.brief" :rows="12"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div class="dialog-footer">
                <el-button @click="dialogVisible = false" style="width: 100px;">取消</el-button>
                <el-button type="primary" @click="handleDesSure" :loading="loadingOne" style="width: 100px; margin-left: 40px;">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import ClassicEditor from '@/components/in-editor/core/ckeditor';
import UploadAdapter from '@/components/in-editor/core/uploadImageAdapter';

const Util = require('@/assets/utils');
import {
    getAllBrief as get,
    updateContentBrief as updateContent,
    updateContentDes as updateDes,
} from "@/api/api";
const code = 200
export default {
    name: "关于元诺",
    data(){
        return{
            query: {
                page: 1,
                size: 10, 
                name: '',
                type: '',
                display: ''
            },
            addQuery: {
                id: '',
                typeZh: '',
                brief: '',
            },
            rules:{
                // brief: [{required: true, message: '请输入简介', trigger: 'blur'}],
            },
            newQuery: {},
            list:{},
            currentPage: 1,
            selects: [],
            dialogTitle: '',
            dialogVisible: false,
            presentVisible: false,
            loadingOne: false,
            showshow: true,
            editor: ClassicEditor,
            // editorData: '<blockquote><p>好好学习，天天向上。</p></blockquote><figure class="image image-style-side"><img src="https://winnorm.oss-cn-chengdu.aliyuncs.com/manage/1620370055169_lawyer_8.jpeg"><figcaption>会议照片</figcaption></figure><p><span style="background-color:rgb(255,255,255);color:rgb(0,0,0);font-size:14px;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 李连杰律师，西南财大法律硕士，四川元诺律师事务所律师。1999年开始从事专职律师工作，主要为公司、企业、事业单位、政府部门提供法律顾问及诉讼服务工作。执业 22 年来，积累了经验，形成了自有特长和风格，擅长风险风控、纠纷化解、商务谈判，赢得客户信赖和肯定。</span></p><p><span style="background-color:rgb(255,255,255);color:rgb(0,0,0);font-size:14px;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 李连杰律师，西南财大法律硕士，四川元诺律师事务所律师。1999年开始从事专职律师工作，主要为公司、企业、事业单位、政府部门提供法律顾问及诉讼服务工作。执业 22 年来，积累了经验，形成了自有特长和风格，擅长风险风控、纠纷化解、商务谈判，赢得客户信赖和肯定。</span></p><p><span style="background-color:rgb(255,255,255);color:rgb(0,0,0);font-size:14px;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 李连杰律师，西南财大法律硕士，四川元诺律师事务律师。1999年开始从事专职律师工作，主要为公司、企业、事业单位、政府部门提供法律顾问及诉讼服务工作。执业 22 年来，积累了经验，形成了自有特长和风格，擅长风险风控、纠纷化解、商务谈判，赢得客户信赖和肯定。</span></p><figure class="image"><img src="https://winnorm.oss-cn-chengdu.aliyuncs.com/manage/1620370055169_lawyer_8.jpeg"><figcaption>参赛的地址</figcaption></figure><p>好好学习，天天向上。</p>',
            editorData: '',
            editorHandleId: '',
            editorConfig:{},
        }
    },
    computed:{
    },
    methods:{
        // 时间格式化
        formatter(row,column){
            return Util.fmt(row.updatedAt)
        },
        getSearchList(){
            this.loadingOne = true
            get(this.query).then(res =>{
                if(res.data.code == code){
                    this.list = res.data.data
                }else{
                    this.$message({
                        type: 'error',
                        message: res.data.msg
                    })
                }
                this.loadingOne = false
            }).catch(() =>{
                this.$message({
                    type: 'error',
                    message:"查询失败,请重试或者联系管理员！！"
                })
                this.loadingOne = false
            })
        },
        // 查询
        handleSearch(){
            this.getSearchList()
        },
        // 履历编辑
        handleBrief(index, row){
            this.presentVisible = true
            this.editorData = row.content
            this.editorHandleId = row.id
            this.dialogTitle = row.typeZh
        },
        // 履历编辑之后确定事件
        handleBriefSure(){
            updateContent({id: this.editorHandleId, content: this.editorData}).then(res =>{
                if(res.data.code == code){
                    this.$message({
                        message: "更新成功",
                        type: "success"
                    });
                    this.presentVisible = false
                    this.getSearchList()
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    })
                }
            }).catch(err =>{
                this.$message({
                    message: err.message,
                    type: 'warning'
                })
            })
        },
        handleDes(index, row){
            this.dialogVisible = true
            this.dialogTitle = row.typeZh
            this.$nextTick(function(){
                this.$refs['addQuery'].resetFields();
                this.newQuery = Object.assign({}, row)
                console.log(this.newQuery)
            })
        },
        handleDesSure(){
            console.log(this.newQuery)
            this.$refs["addQuery"].validate(valid =>{
                if(valid){
                    updateDes({id: this.newQuery.id, brief: this.newQuery.brief}).then(res =>{
                        if(res.data.code == code){
                            this.$message({
                                message: "更新成功",
                                type: "success"
                            });
                            this.dialogVisible = false
                            this.getSearchList()
                        }else{
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            })
                        }
                    }).catch(err =>{
                        this.$message({
                            message: err.message,
                            type: 'warning'
                        })
                    })
                }
            })
        },
        // 编辑器
        ckeditorReady(editor){
            console.log(' === 编辑器初始化完成')
            editor.plugins.get("FileRepository").createUploadAdapter = loader =>{
                return new UploadAdapter(loader)
            }
        },
    },
    mounted(){
        this.getSearchList()
    },
    components:{
    }
}
</script>
<style lang="less">
.search .el-form-item{
    margin-right: 50px;
}
.block{
    margin-top: 10px;
}
.el-pagination{
    float: right;
}
.avatar-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 178px;
    height: 178px;
}
.avatar-uploader:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 178px;
    /* height: 178px; */
    display: block;
}
.dialog-footer{
    text-align: center;
}
.dialog-footer .tips{
    line-height: 40px;
    color: red;
    /* background-color: yellow; */
    font-size: 10px;
    // text-align: right;
}
// 头部
.dialog-ckeditor-bg{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 500px;
    width: 1100px;
    height: 100%;
    width: 100%;
    background-color: white;
}
.ckeditor-header{
    height: 80px;
    width: 1100px;
    display: flex;
    margin: 0 auto;
}
.ckeditor-header-item{
    height: 100%;
}
.ckeditor-title{
    width: 100%;
    line-height: 80px;
    font-size: 20px;
    text-align: center;
    color: rgb(99, 99, 99);
}

.dialog-ckeditor{
    width: 1100px;
    margin: 0 auto;
}


// elementui 表格修改边框后下面还是有一条白线
.el-table::before {
    height: 0;  // 将高度修改为0
}

/* --------- EDITOR STYLES  ---------------------------------------------------------------------------------------- */

.editor__editable,
/* Classic build. */
main .ck-editor[role='application'] .ck.ck-content,
/* Decoupled document build. */
.ck.editor__editable[role='textbox'],
.ck.ck-editor__editable[role='textbox'],
/* Inline & Balloon build. */
.ck.editor[role='textbox'] {
	// width: 100%;
	// background: #fff;
    // background: rebeccapurple;
	font-size: 1em;
	line-height: 1.6em;
	min-height: var(--ck-sample-editor-min-height);
	padding: 1.5em 2em;
}
.ck-editor__editable {
  height: 800px;
}

</style>